import ApiService from "@/core/services/ApiService";

export const requestLoginOtpMobile = async (payload) => {
  return await ApiService.post(`signin/request`, payload);
};

export const validateLoginOtpMobile = async (payload) => {
  return await ApiService.post(`signin/request`, payload);
};

export const requestRegisterOtpMobile = async (payload) => {
  return await ApiService.post(`register/request`, payload);
};

export const validateRegisterOtpMobile = async (payload) => {
  return await ApiService.post(`register/request`, payload);
};

export const resendLoginOtpMobile = async (payload) => {
  return await ApiService.post(`signin/resent`, payload);
};

export const resendRegisterOtpMobile = async (payload) => {
  return await ApiService.post(`register/resent`, payload);
};
